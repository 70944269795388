<template>
    <v-dialog v-model="showProfileDialog" max-width="500">
      <v-card>
        <ProfileView ref="profileView" popupMode @closeClick="showProfileDialog=false"/>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import ProfileView from '@/components/profile/ProfileView.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileDialog",
  components: {
    ProfileView,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      showProfileDialog: false,
    };
  },
  mounted() {
  },
  methods: {
    async open(id) {
      this.showProfileDialog = true;
      await this.$nextTick();
      this.$refs.profileView.loadById(id);
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped>
  .account-button {}
  .account-button::after { content: '\f078'; font-family: 'Font Awesome 5 Pro'; margin-left:-7px; margin-right: 2px; }
  .v-application--is-rtl .account-button::after { margin-right:-7px; margin-left: 2px; }
</style>