<template>
  <v-card v-if="profile">
    <v-toolbar dark extended extension-height="24" class="profile--header">
      <v-avatar v-if="profile && profile.img" size="80" class="profile--img"><img :src="profile.img+(profile.img.indexOf('?')>0?'&':'?')+'w=250&h=250'" :title="`Profile Picture of ${profile.name}`"/></v-avatar>
      <v-toolbar-title class="pl-4">
        {{ profile.name }}
      </v-toolbar-title>
      <v-spacer/>
      <v-btn v-if="popupMode" text color="white" @click="$emit('closeClick')"><v-icon class="mr-2">fa-times-circle</v-icon> {{$t('shared.close')}}</v-btn>
      <!-- <ShareButton v-if="result && event && race && !isEmbeddedInApp" :text="`Results at the ${event.name} - Check sodisp.com`" color="white" :route="{name: 'raceResult', params: { id: event.id, raceId: race.id, resultId: result.id}}" /> -->
    </v-toolbar>    
    <div style="margin-top:-20px" :class="'pl-4 profile--actions ' + (profile.img ? 'with-img' : '')">
      <v-btn v-if="false" outlined rounded class="mr-2 mb-2" color="primary" style="background:white;" @click="requestFriend">
        <v-icon class="mr-2">fa-user-plus</v-icon> {{$t('results.details.add-friend')}}
      </v-btn>
    </div>

    <v-card-text v-if="!profile.badges">
      Detailed profile information is not available due to privacy settings.
    </v-card-text>

    <v-card-text v-if="profile.badges && profile.badges.length">
      <h3>Badges</h3>
      <v-slide-group
        multiple
        show-arrows
        class="mt-0"
        
      >
        <v-slide-item
          v-for="(item, idx) in profile.badges"
          :key="idx"
          v-slot="{ active, toggle }"
          
        >
          <v-card :elevation="1" :input-value="active" color="white" class="ma-1 pa-1 text-center badge-card">
            <img :src="item.img" class="pa-2" :style="`max-width: 100%;`" :title="item.name" :alt="item.name" />
            <div class="text-muted">
              {{ item.first_reward_date | localDate('L')}}
            </div>
          </v-card>       
        </v-slide-item>
      </v-slide-group>      
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileView",
  props: {
    popupMode: Boolean,
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
    };
  },
  mounted() {
  },
  methods: {
    async loadById(id) {
      this.profile = (await profileService.publicProfile(id)).data;
    },

    async requestFriend() {
      if (confirm(this.$t('profile.friends.request-as-friend-msg', {user: this.result.name }))){
        const response = (await profileService.requestFriend(this.result.id)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.request-confirmation'));
      }
    },


  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss" scoped>
  .profile--header {
    .profile--img { position: relative; top: 30px; left: 10px; margin-right:10px; }
  }
  .profile--actions.with-img { margin-left: 90px; margin-bottom: 0px;}
  .profile--actions {
  }

  .badge-card { height: 100px; max-width: 100px;}
</style>